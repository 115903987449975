<template>
  <div class="share-device">
    <div class="tool-bar" v-if="!loading">
      <div :class="{ 'form-error': $v.selectDevice.$error }">
        <div class="field-label">Device</div>
        <b-select
          v-model="$v.selectDevice.$model"
          :options="deviceList"
          placeholder="Select Device"
          class="text-input sm"
        ></b-select>
        <div class="input-error" v-if="!$v.selectPermission.required">Field is Required</div>
      </div>
      <div :class="{ 'form-error': $v.selectEmail.$error }">
        <label class="field-label" for="email-address">Email Address</label>
        <input type="email" v-model="$v.selectEmail.$model" class="text-field w-input"
                      maxlength="256" placeholder="Email Address" id="email-address">
        <div class="input-error" v-if="!$v.selectEmail.required">Field is Required</div>
        <div class="input-error" v-if="!$v.selectEmail.email">Must be a valid email address</div>
      </div>
      <div :class="{ 'form-error': $v.selectPermission.$error }">
        <div class="field-label">Permission</div>
        <b-select
          v-model="$v.selectPermission.$model"
          :options="permissionOpts"
          placeholder="Icon Must Include..."
          class="text-input sm"
        ></b-select>
        <div class="input-error" v-if="!$v.selectPermission.required">Field is Required</div>
      </div>

      <button class="button" @click="addShare">Add Share</button>
    </div>
    <div>Share List:</div>
    <div class="share-list">
      <b-table striped hover :items="shares" :fields="shareFields" select-mode="single" selected-variant="success"
               td-class="admin-device-table-data" th-class="admin-device-table-header">
        <!--Select All Header Element-->
        <template v-slot:head(selected)="row">
          <b-form-checkbox v-model="selectAll" v-on:change="toggleSelectAll"></b-form-checkbox>
        </template>
        <!--Select Checkbox Row Element-->
        <template v-slot:cell(selected)="row">
          <b-form-checkbox v-model="row.item.selected"></b-form-checkbox>
        </template>
        <!--User Actions Row Element-->
        <template v-slot:cell(actions)="row">
          <i class="fa fa-times-circle row-icon-button-danger row-action ml-2" @click="removeShare(row.item)" v-b-tooltip.hover title="Delete Device"></i>
        </template>
      </b-table>
    </div>
      <div class="footer mt-2" v-if="this.modal">
        <button class="button" @click="clickClose()" id="button-close">Close</button>
      </div>

  </div>
</template>

<script>
import * as DataProvider from '../helpers/DataProvider'
import * as ErrorHelper from '../helpers/ErrorHelper'
import { email, required } from 'vuelidate/lib/validators'

export default {
  name: 'share-device',
  props: {
    modal: String,
    user_email: String,
    device_list: Array
  },
  data: function () {
    return {
      showButtons: true,
      loading: true,
      selectDevice: '',
      selectEmail: '',
      selectPermission: '',
      deviceList: [{
        text: 'All',
        value: 'all'
      }],
      shares: [],
      permissionOpts: [
        {
          text: 'Read Only',
          value: 'read'
        },
        {
          text: 'Read/Write',
          value: 'write'
        }
      ],
      selectAll: false,
      shareFields: [
        {
          key: 'selected',
          label: '-'
        },
        { key: 'device_code',
          label: 'Code',
          sortable: true
        },
        { key: 'device_name',
          label: 'Name',
          sortable: true
        },
        { key: 'email',
          label: 'Email',
          sortable: true
        },
        { key: 'permission_level',
          label: 'Permission',
          sortable: true
        },
        { key: 'actions',
          label: 'Actions',
          sortable: false
        }
      ]
    }
  },
  validations: {
    selectEmail: {
      required,
      email
    },
    selectPermission: {
      required
    },
    selectDevice: {
      required
    }
  },
  async created () {
    // Hide Controls so the parent modal can display them
    if (this.isModal) {
      this.showButtons = false
    }
    this.refreshShares()
    if (this.device_list) {
      this.adminLoadDevices(this.device_list)
    } else {
      await this.loadDeviceList()
    }
    this.loading = false
  },
  methods: {
    refreshShares: async function () {
      if (this.user_email) {
        await this.loadAdminShareList(this.user_email)
      } else {
        await this.loadShareList()
      }
    },
    loadShareList: async function () {
      let res = await DataProvider.getShareList()
      if (!res.success) {
        ErrorHelper.displayDataErrorToast(res)
        this.loading = false
        return
      }
      this.shares = res.data.permission_list
    },
    loadAdminShareList: async function (email) {
      let res = await DataProvider.adminGetUserShareList(email)
      if (!res.success) {
        ErrorHelper.displayDataErrorToast(res)
        this.loading = false
        return
      }
      this.shares = res.data.permission_list
    },
    loadDeviceList: async function () {
      let res = await DataProvider.getDeviceList()
      if (!res.success) {
        ErrorHelper.displayDataErrorToast(res)
        this.loading = false
        return
      }
      for (let device in res.data) {
        this.deviceList.push({
          text: res.data[device].device_name,
          value: res.data[device].device_imei
        })
      }
    },
    adminLoadDevices: function (deviceData) {
      deviceData.forEach(x => {
        this.deviceList.push({
          text: x.device_name,
          value: x.device_imei
        })
      })
    },
    addShare: async function () {
      if (this.checkShareDuplicates(this.selectDevice, this.selectEmail)) {
        ErrorHelper.displayGeneralErrorToast('You have already shared that device with that email address!', 'Duplicate Share')
        return
      }
      let res
      if (this.user_email) {
        res = await DataProvider.adminAddShare(this.selectDevice, this.selectEmail, this.selectPermission, this.user_email)
      } else {
        res = await DataProvider.addShare(this.selectDevice, this.selectEmail, this.selectPermission)
      }
      if (res.success) {
        this.$bvToast.toast('Share Added Successfully.', {
          title: 'Update Successful',
          variant: 'success',
          toaster: 'b-toaster-top-center'
        })
        this.refreshShares()
      } else {
        ErrorHelper.displayDataErrorToast(res)
      }
    },
    checkShareDuplicates: function (imei, email) {
      return this.shares.filter(x => x.device_imei === imei && x.email === email).length > 0
    },
    clickClose: function () {
      // Check if we've been passed a modal to close.
      if (this.modal) {
        this.$bvModal.hide(this.modal)
      } else {
        this.$router.go(-1)
      }
    },
    removeShare: async function (share) {
      let res = await DataProvider.removeShare(share.device_imei, share.email)
      if (res.success) {
        this.$bvToast.toast('Share Removed.', {
          title: 'Update Successful',
          variant: 'success',
          toaster: 'b-toaster-top-center'
        })
        this.refreshShares()
      } else {
        ErrorHelper.displayDataErrorToast(res)
      }
    },
    toggleSelectAll: function (state) {
      this.shares.forEach(x => { x.selected = state })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../variables';

  .share-device{
    font-family: 'Open Sans', sans-serif;
    color: $theme-color-primary-3;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
    display: flex;
    flex-direction: column;
  }

  .tool-bar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  //.field-label {
  //  margin-bottom: 0.5rem;
  //  font-size: 12px;
  //}

  .device-code{
    color: $text-color-invert;
    margin-left: 5%;
  }

  .footer{
    width: 100%;
    bottom: 2%;
    display: flex;
    justify-content: flex-end;
    margin: 0 1% 0 1%;
  }

  .icon_select_color {
    border: solid $theme-color-primary-3;
  }

  .share-list {
    display: flex;
    flex-direction: column;
    overflow: auto;
    max-height: 50vh;
    background: $theme-color-background-2;
  }

</style>
