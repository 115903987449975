var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "share-device" }, [
    !_vm.loading
      ? _c("div", { staticClass: "tool-bar" }, [
          _c(
            "div",
            { class: { "form-error": _vm.$v.selectDevice.$error } },
            [
              _c("div", { staticClass: "field-label" }, [_vm._v("Device")]),
              _c("b-select", {
                staticClass: "text-input sm",
                attrs: {
                  options: _vm.deviceList,
                  placeholder: "Select Device"
                },
                model: {
                  value: _vm.$v.selectDevice.$model,
                  callback: function($$v) {
                    _vm.$set(_vm.$v.selectDevice, "$model", $$v)
                  },
                  expression: "$v.selectDevice.$model"
                }
              }),
              !_vm.$v.selectPermission.required
                ? _c("div", { staticClass: "input-error" }, [
                    _vm._v("Field is Required")
                  ])
                : _vm._e()
            ],
            1
          ),
          _c("div", { class: { "form-error": _vm.$v.selectEmail.$error } }, [
            _c(
              "label",
              { staticClass: "field-label", attrs: { for: "email-address" } },
              [_vm._v("Email Address")]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.$v.selectEmail.$model,
                  expression: "$v.selectEmail.$model"
                }
              ],
              staticClass: "text-field w-input",
              attrs: {
                type: "email",
                maxlength: "256",
                placeholder: "Email Address",
                id: "email-address"
              },
              domProps: { value: _vm.$v.selectEmail.$model },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.$v.selectEmail, "$model", $event.target.value)
                }
              }
            }),
            !_vm.$v.selectEmail.required
              ? _c("div", { staticClass: "input-error" }, [
                  _vm._v("Field is Required")
                ])
              : _vm._e(),
            !_vm.$v.selectEmail.email
              ? _c("div", { staticClass: "input-error" }, [
                  _vm._v("Must be a valid email address")
                ])
              : _vm._e()
          ]),
          _c(
            "div",
            { class: { "form-error": _vm.$v.selectPermission.$error } },
            [
              _c("div", { staticClass: "field-label" }, [_vm._v("Permission")]),
              _c("b-select", {
                staticClass: "text-input sm",
                attrs: {
                  options: _vm.permissionOpts,
                  placeholder: "Icon Must Include..."
                },
                model: {
                  value: _vm.$v.selectPermission.$model,
                  callback: function($$v) {
                    _vm.$set(_vm.$v.selectPermission, "$model", $$v)
                  },
                  expression: "$v.selectPermission.$model"
                }
              }),
              !_vm.$v.selectPermission.required
                ? _c("div", { staticClass: "input-error" }, [
                    _vm._v("Field is Required")
                  ])
                : _vm._e()
            ],
            1
          ),
          _c("button", { staticClass: "button", on: { click: _vm.addShare } }, [
            _vm._v("Add Share")
          ])
        ])
      : _vm._e(),
    _c("div", [_vm._v("Share List:")]),
    _c(
      "div",
      { staticClass: "share-list" },
      [
        _c("b-table", {
          attrs: {
            striped: "",
            hover: "",
            items: _vm.shares,
            fields: _vm.shareFields,
            "select-mode": "single",
            "selected-variant": "success",
            "td-class": "admin-device-table-data",
            "th-class": "admin-device-table-header"
          },
          scopedSlots: _vm._u([
            {
              key: "head(selected)",
              fn: function(row) {
                return [
                  _c("b-form-checkbox", {
                    on: { change: _vm.toggleSelectAll },
                    model: {
                      value: _vm.selectAll,
                      callback: function($$v) {
                        _vm.selectAll = $$v
                      },
                      expression: "selectAll"
                    }
                  })
                ]
              }
            },
            {
              key: "cell(selected)",
              fn: function(row) {
                return [
                  _c("b-form-checkbox", {
                    model: {
                      value: row.item.selected,
                      callback: function($$v) {
                        _vm.$set(row.item, "selected", $$v)
                      },
                      expression: "row.item.selected"
                    }
                  })
                ]
              }
            },
            {
              key: "cell(actions)",
              fn: function(row) {
                return [
                  _c("i", {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        modifiers: { hover: true }
                      }
                    ],
                    staticClass:
                      "fa fa-times-circle row-icon-button-danger row-action ml-2",
                    attrs: { title: "Delete Device" },
                    on: {
                      click: function($event) {
                        return _vm.removeShare(row.item)
                      }
                    }
                  })
                ]
              }
            }
          ])
        })
      ],
      1
    ),
    this.modal
      ? _c("div", { staticClass: "footer mt-2" }, [
          _c(
            "button",
            {
              staticClass: "button",
              attrs: { id: "button-close" },
              on: {
                click: function($event) {
                  return _vm.clickClose()
                }
              }
            },
            [_vm._v("Close")]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }